<template>
  <div class>
    <v-row
      class="mt-5 ml-5 pt-3"
      v-if="showtop"
    >

      <v-btn
        size="large"
        variant="text"
        color="accent"
        class="font-weight-bold btn-font"
        @click="addDoc()"
      >
        <v-icon
          size="30"
          left
        >mdi-plus-box</v-icon>
        Add New Title
        <v-tooltip
          activator="parent"
          location="top"
        >Add Title</v-tooltip>
      </v-btn>
      <v-btn
        size="large"
        variant="text"
        color="accent"
        class="font-weight-bold, btn-font"
        @click="reSort()"
      >
        <v-icon
          size="30"
          left
        >mdi-sort</v-icon>
        Resort Titles
        <v-tooltip
          activator="parent"
          location="top"
        >Sort Titles</v-tooltip>
      </v-btn>
    </v-row>
    <v-col class="mb-4">
      <v-card
        class="bg-primary"
        v-if="editdoc || adddoc"
      >
        <v-card-title v-if="editdoc">
          <h2 class="text-accent">Edit Title</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="text-accent">Add New Title</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent
          >
            <v-text-field
              color="accent"
              class="edit-font"
              label="Title"
              v-model.lazy="curdoc.heading"
            ></v-text-field>
            <v-text-field
              color="accent"
              class="edit-font"
              type="number"
              label="Sort"
              v-model.lazy="curdoc.sort"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit()"
              class="bg-success mx-0 mt-3 mr-3 btn-font"
            >Save Topic</v-btn>
            <v-btn
              text
              @click="
                  editdoc = false;
                  adddoc = false;
                  showtop = true;
                  dispdoc = true;
                  curdoc = [];
                "
              class="bg-success mx-0 mt-3 btn-font"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <div
      class="bg-secondary pt-2"
      v-for="project in projects"
      :key="project.id"
    >
      <v-row
        dense
        no-gutters
        class="pt-1"
      >
        <v-col
          poemproject
          class="poem mt-2 mb-2 ml-5"
          sm="7"
          md="6"
          lg="5"
          xl="4"
        >
          <p class="item-title mb-0">{{ project.heading }}</p>
        </v-col>
        <v-col
          sm="1"
          md="1"
          lg="1"
          xl="1"
        >
          <p
            @click="sortComp(poem.id)"
            class="text-center poem mb-0"
          >
            {{ project.sort }}
          </p>
        </v-col>
        <v-col
          sm="3"
          md="3"
          lg="3"
          xl="3"
        >

          <v-btn
            size="small"
            variant="text"
            color="black"
            class="btn-font"
            @click="editDoc(project.id)"
          >
            <v-icon
              size="20"
              left
            >mdi-pencil</v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >Edit Title</v-tooltip>
          </v-btn>

          <v-btn
            size="small"
            variant="text"
            color="black"
            class="btn-font"
            @click="delDoc(project.id)"
          >
            <v-icon
              size="20"
              left
            >mdi-delete</v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >Delete Title</v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from "vue";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import shareData from "@/composables/share";
import axios from "axios";

const { scrollToTop, projects, fetchDocs } = shareData();

let tid = ref("headings");
let defurl = ref("https://angelnicoleworld.com/data.php");
let curdoc = ref([]);
let editdoc = ref(false);
let adddoc = ref(false);
let dispdoc = ref(false);
let showtop = ref(true);
var redraw = false;
let search = ref("");
let formdata = ref([]);

onMounted(async () => {
  formdata = {
    action: "display",
    search: search.value,
  };
  await fetchDocs(formdata, redraw, defurl);
});

function submit() {
  var url = defurl.value;
  var action;
  if (editdoc.value) {
    action = "submit";
  } else {
    action = "add";
  }
  var formdata = {
    action: action,
    id: curdoc.value.id,
    heading: curdoc.value.heading,
    sort: curdoc.value.sort,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "display",
        search: search.value,
      };
      fetchDocs(formdata, redraw, defurl);
    })
    .catch((error) => alert(error));
  editdoc.value = false;
  adddoc.value = false;
  curdoc.value = [];
  projects.value = [];
  dispdoc.value = false;
  showtop.value = true;
}

function editDoc(id) {
  var url = defurl.value;
  var formdata = {
    action: "edit",
    id: id,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      const data = res.data;
      curdoc.value = data[0];
      editdoc.value = true;
      showtop.value = false;
      dispdoc.value = false;
    })
    .catch((error) => alert(error));
}

function addDoc() {
  adddoc.value = true;
  showtop.value = false;
  dispdoc.value = false;
  curdoc.value = [];
}

function delDoc(id) {
  if (confirm("Do you really want to delete?")) {
    var url = defurl.value;
    var formdatad = {
      action: "del",
      id: id,
    };
    axios
      .post(url, formdatad, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then(() => {
        formdata = {
          action: "display",
          search: search.value,
        };
        fetchDocs(formdata, redraw, defurl);
      })
      .catch((error) => alert(error));
  }
}

function reSort() {
  var url = defurl.value;
  var formdata = {
    action: "resort-t",
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "display",
        search: search.value,
      };
      fetchDocs(formdata, redraw, defurl);
    })
    .catch((error) => alert(error));
}
</script>
  
<style scoped>
td .poemtxt {
  font-size: 2rem !important;
  color: red;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
</style>
  