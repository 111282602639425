import { createRouter, createWebHistory } from 'vue-router'
import Headings from '../views/Headings.vue'
import EditAbout from '../views/EditAbout.vue'
import EditBook from '../views/EditBook.vue'
import Poems from '../views/Poems.vue'
import Photos from '../views/Photos.vue'

const routes = [
  {
    path: '/',
    name: 'Headings',
    component: Headings
  },
  {
    path: "/editabout",
    name: "editabout",
    component: EditAbout
  },
  {
    path: "/editbook",
    name: "editbook",
    component: EditBook
  },
  {
    path: "/poems",
    name: "poems",
    component: Poems
  },
  {
    path: "/photos",
    name: "photos",
    component: Photos
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
