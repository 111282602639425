<template>
  <div class>
    <v-row
      class="mt-5 ml-5 pt-3"
      v-if="showtop"
    >

      <v-btn
        size="large"
        variant="text"
        color="accent"
        class="font-weight-bold btn-font"
        @click="addDoc()"
      >
        <v-icon
          size="30"
          left
        >mdi-plus-box</v-icon>
        Add New Photo
        <v-tooltip
          activator="parent"
          location="top"
        >Add Photo</v-tooltip>
      </v-btn>
      <v-btn
        size="large"
        variant="text"
        color="accent"
        class="font-weight-bold, btn-font"
        @click="reSort()"
      >
        <v-icon
          size="30"
          left
        >mdi-sort</v-icon>
        Resort Photos
        <v-tooltip
          activator="parent"
          location="top"
        >Sort Photos</v-tooltip>
      </v-btn>
    </v-row>
    <v-col class="mb-4">
      <v-card
        class="bg-primary"
        v-if="editdoc || adddoc"
      >
        <v-card-title v-if="editdoc">
          <h2 class="text-accent">Edit Photo</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="text-accent">Add New Photo</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent
          >
            <v-text-field
              color="accent"
              class="edit-f"
              label="Photo"
              v-model.lazy="curdoc.pic"
            ></v-text-field>
            <v-file-input
              label="File Input"
              v-model="file"
              @change="fileChanged"
            ></v-file-input>
            <v-text-field
              color="accent"
              class="edit-f"
              label="Tag"
              v-model.lazy="curdoc.tag"
            ></v-text-field>
            <v-text-field
              color="accent"
              class="edit-f"
              type="number"
              label="Sort"
              v-model.lazy="curdoc.sort"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit()"
              class="bg-success mx-0 mt-3 mr-3 btn-font"
            >Save Photo</v-btn>
            <v-btn
              text
              @click="
                  editdoc = false;
                  adddoc = false;
                  showtop = true;
                  dispdoc = false;
                  curdoc = [];
                "
              class="bg-success mx-0 mt-3 btn-font"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <table
      class="mytable mb-10"
      v-if="!dispdoc && !(adddoc || editdoc)"
    >
      <tbody>
        <tr>
          <th class="title text-accent font-weight-bold">
            Pic
          </th>
          <th class="title text-accent font-weight-bold">
            Tag
          </th>
          <th class="title text-accent font-weight-bold numbers">
            Sort
          </th>
          <th class="title text-accent font-weight-bold">Actions</th>
        </tr>

        <tr
          v-for="project in projects"
          :key="project.id"
        >
          <td
            v-if="!project.pic"
            class="item-title item text-black pt-2 pb-2"
            @click="editTxt(project.id)"
          >
            No Results
          </td>
          <td
            v-if="project.pic"
            class="item-title item text-black pt-2 pb-2"
            @click="editTxt(project.id)"
          >
            {{ project.pic }}
          </td>
          <td
            v-if="project.pic"
            class="item-title item text-black pt-2 pb-2"
            @click="editTxt(project.id)"
          >
            {{ project.tag }}
          </td>
          <td class="item-title numbers dates text-black pt-2 pb-2 ">
            {{ project.sort }}
          </td>
          <td v-if="project.pic">
            <v-btn
              size="small"
              variant="text"
              color="black"
              class="btm-font"
              @click="editDoc(project.id)"
            >
              <v-icon
                size="20"
                left
              >mdi-pencil</v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >Edit Photo</v-tooltip>
            </v-btn>

            <v-btn
              size="small"
              variant="text"
              color="black btn-font"
              class="btn-fnt"
              @click="delDoc(project.id)"
            >
              <v-icon
                size="20"
                left
              >mdi-delete</v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >Delete Photo</v-tooltip>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-if="dispdoc"
    row
    class="bg-primary"
  >
    <v-card
      v-if="dispdoc && !editdoc"
      row
      flat
      class="bg-primary"
    >
      <v-card-text>
        <v-row class="mb-5 bg-primary">
          <v-col
            class
            sm="12"
            md="12"
            lg="10"
            xl="8"
          >
            <h1 class="text-accent text-left">
              {{ curdoc.tag }}
              <v-btn
                size="small"
                variant="text"
                color="accent"
                class="btn-font"
                @click="(dispdoc = false), (showtop = true)"
              >
                <v-icon
                  size="40"
                  left
                >mdi-close</v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >Close</v-tooltip>
              </v-btn>
            </h1>
          </v-col>
        </v-row>
        <v-row class="primary ml-5 mr-5">
          <v-col
            class="ck-content"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <hr class="secondary thick" />
            <div class="img-box">
              <v-img
                :src="curdoc.pic"
                ratio="4/3"
                class="mb-5"
                width="1200"
                height="900"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from "vue";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import shareData from "@/composables/share";
import axios from "axios";

const { scrollToTop, projects, fetchDocs } = shareData();

let tid = ref("headings");
let defurl = ref("https://angelnicoleworld.com/data.php");
let picurl = ref("https://dash.angelnicoleworld.com/images2/");
let curdoc = ref([]);
let editdoc = ref(false);
let adddoc = ref(false);
let dispdoc = ref(false);
let showtop = ref(true);
var redraw = false;
let search = ref("");
let formdata = ref([]);
let file = ref([]);
let fontSize = ref(16);

onMounted(async () => {
  formdata = {
    action: "display-p",
    search: search.value,
  };
  await fetchDocs(formdata, redraw, defurl);
});

function submit() {
  var url = defurl.value;
  var action;
  if (editdoc.value) {
    action = "submit-p";
  } else {
    action = "add-p";
  }
  var formdata = {
    action: action,
    id: curdoc.value.id,
    pic: curdoc.value.pic,
    tag: curdoc.value.tag,
    sort: curdoc.value.sort,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "display-p",
        search: "",
      };
      fetchDocs(formdata, redraw, defurl);
    })
    .catch((error) => alert(error));
  editdoc.value = false;
  adddoc.value = false;
  curdoc.value = [];
  projects.value = [];
  dispdoc.value = false;
  showtop.value = true;
}

function fileChanged(val) {
  curdoc.value.pic = file.value[0].name;
}

function editTxt(id) {
  for (var i = 0; i < projects.value.length; i++) {
    if (projects.value[i].id === id) {
      curdoc.value.pic = picurl.value + projects.value[i].pic;
      curdoc.value.tag = projects.value[i].tag;
      if (curdoc.value.pic) {
        dispdoc.value = true;
        showtop.value = false;
      }
    }
  }
  // fetchDocs(formdata, redraw, "displayj");
  scrollToTop();
}

function editDoc(id) {
  var url = defurl.value;
  var formdata = {
    action: "edit-p",
    id: id,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      const data = res.data;
      curdoc.value = data[0];
      editdoc.value = true;
      showtop.value = false;
      dispdoc.value = false;
      file.value = [];
    })
    .catch((error) => alert(error));
}

function addDoc() {
  adddoc.value = true;
  showtop.value = false;
  dispdoc.value = false;
  curdoc.value = [];
  file.value = [];
}

function delDoc(id) {
  if (confirm("Do you really want to delete?")) {
    var url = defurl.value;
    var formdatad = {
      action: "del-p",
      id: id,
    };
    axios
      .post(url, formdatad, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then(() => {
        formdata = {
          action: "display-p",
          search: search.value,
        };
        fetchDocs(formdata, redraw, defurl);
      })
      .catch((error) => alert(error));
  }
}

function reSort() {
  var url = defurl.value;
  var formdata = {
    action: "resort-p",
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "display-p",
        search: search.value,
      };
      fetchDocs(formdata, redraw, defurl);
    })
    .catch((error) => alert(error));
}
</script>
  
<style scoped>
.edit-f {
  letter-spacing: 1px;
  line-height: 150%;
  font-family: "Esteban", serif !important;
}
td .poemtxt {
  font-size: 2rem !important;
  color: red;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
</style>
  