<template>
  <div class="bg-primary">
    <v-row
      class="mt-5 ml-5 mb-5 pt-3"
      v-if="showtop"
    >
      <h2 class="text-accent">Edit Book</h2>
    </v-row>
    <v-col
      v-if="editdoc || adddoc"
      xs12
      sm3
      md8
      class="mb-4"
    >
      <v-card class="bg-primary">
        <v-card-title v-if="editdoc">
          <h2 class="text-accent">Edit Book</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="text-accent">Add New Book</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent
          >
            <v-text-field
              color="accent"
              class="btn-font"
              label="Title"
              type="text"
              v-model.lazy="curdoc.title"
            ></v-text-field>
            <ckeditor
              :editor="editor"
              v-model="curdoc.content"
              :config="editorConfig"
            ></ckeditor>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              color="black"
              @click="submit"
              class="bg-success mx-0 mt-3 mr-3 btn-font"
            >Save Book</v-btn>
            <v-btn
              text
              color="black"
              @click="
                editdoc = false;
                adddoc = false;
                showtop = true;
                dispdoc = false;
                curdoc = [];
              "
              class="mx-0 mt-3 btn-font"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="12"
      md="12"
      lg="12"
      xl="10"
    >
      <div
        v-if="!dispdoc && !(adddoc || editdoc)"
        class="bg-secondary pt-2"
        v-for="project in projects"
        :key="project.id"
      >
        <v-row
          dense
          no-gutters
          class="ml-3 pt-1"
        >
          <v-col
            @click="editTxt(project.id)"
            poemproject
            class="book mb-2"
            sm="3"
            md="3"
            lg="5"
            xl="6"
          >
            <h4>{{ project.title }}</h4>
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <v-btn
              size="small"
              variant="text"
              color="black"
              class="btn-font"
              @click="editDoc(project.id)"
            >
              <v-icon
                size="20"
                left
              >mdi-pencil</v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >Edit Book</v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <div
      v-if="dispdoc"
      row
      class="bg-primary"
    >
      <v-card
        v-if="dispdoc && !editdoc"
        row
        flat
        class="bg-primary"
      >
        <v-card-text>
          <v-row class="mb-5 bg-primary">
            <v-col
              class
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <h1 class="text-accent text-left">
                {{ curdoc.title }}
                <v-btn
                  size="small"
                  variant="text"
                  color="accent"
                  class="btn-font"
                  @click="(dispdoc = false), (showtop = true)"
                >
                  <v-icon
                    size="40"
                    left
                  >mdi-close</v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >Close</v-tooltip>
                </v-btn>
              </h1>
            </v-col>
          </v-row>
          <v-row class="bg-secondary">
            <v-col
              class="ck-content"
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <hr class="bg-secondary thick" />
              <div
                class="bg-secondary pl-5 pt-5 pb-5 black--text"
                v-html="curdoc.content"
              ></div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import shareData from "@/composables/share";
import axios from "axios";

const { scrollToTop, projects, fetchDocs } = shareData();

let search = ref("");
let curdoc = ref([]);
let refresh = ref(0);
let editdoc = ref(false);
let adddoc = ref(false);
let dispdoc = ref(false);
let showtop = ref(true);
let formdata = ref([]);
let defurl = ref("https://angelnicoleworld.com/data.php");
var redraw = false;

onMounted(async () => {
  formdata = {
    action: "displayab",
    search: search.value,
    tid: "book",
  };
  await fetchDocs(formdata, redraw, defurl);
});

const editor = ref(DocumentEditor);
const editorConfig = ref({
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: "https://dash.angelnicoleworld.com/upload.php",

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      "X-CSRF-TOKEN": "CSFR-Token",
      Authorization: "Bearer <JSON Web Token>",
    },
  },
  image: {
    resizeUnit: "px",
    // You need to configure the image toolbar, too, so it uses the new style buttons.
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
    ],

    styles: [
      "alignCenter",

      // This represents an image aligned to the left.
      "alignLeft",

      // This represents an image aligned to the right.
      "alignRight",
    ],
  },
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,

    // Let the users control the "download" attribute of each link.
    decorators: [
      {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "download",
        },
      },
    ],
  },
});

function onReady(editor) {
  // Insert the toolbar before the editable area.
  editor.ui
    .getEditableElement()
    .parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
}

function submit() {
  var url = defurl.value;
  var action;
  if (editdoc.value) {
    action = "submit-j";
  } else {
    action = "add-j";
  }
  var formdatas = {
    action: action,
    id: curdoc.value.id,
    title: curdoc.value.title,
    content: curdoc.value.content,
    tid: "book",
  };
  axios
    .post(url, formdatas, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "displayab",
        search: search.value,
        tid: "book",
      };
      fetchDocs(formdata, redraw, defurl);
    })
    .catch((error) => alert(error));
  editdoc.value = false;
  adddoc.value = false;
  curdoc.value = [];
  projects.value = [];
  dispdoc.value = false;
  showtop.value = true;
}

function editDoc(id) {
  var url = defurl.value;
  var formdata = {
    action: "edit-j",
    id: id,
    tid: "book",
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      const data = res.data;
      curdoc.value = data[0];
      editdoc.value = true;
      showtop.value = false;
      dispdoc.value = false;
    })
    .catch((error) => alert(error));
}

function editTxt(id) {
  for (var i = 0; i < projects.value.length; i++) {
    if (projects.value[i].id === id) {
      curdoc.value.title = projects.value[i].title;
      curdoc.value.content = projects.value[i].content;
      if (curdoc.value.content) {
        dispdoc.value = true;
        showtop.value = false;
      }
    }
  }
  // fetchDocs(formdata, redraw, "displayj");
  scrollToTop();
}

function addDoc() {
  adddoc.value = true;
  showtop.value = false;
  dispdoc.value = false;
  curdoc.value = [];
}

function delDoc(id) {
  if (confirm("Do you really want to delete?")) {
    var url = defurl.value;
    var formdatad = {
      action: "del-j",
      id: id,
      tid: "book",
    };
    axios
      .post(url, formdatad, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then(() => {
        fetchDocs(formdata, redraw, "displayj");
      })
      .catch((error) => alert(error));
  }
}
</script>

<style scoped>
.topic-title input {
  color: red !important;
}
.done {
  color: #689f38;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
.book:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
</style>
