<template>
  <div class="bg-primary">
    <v-row
      v-if="showtop"
      class="bg-primary pa-3"
    >
      <v-col>
        <v-btn
          size="large"
          variant="text"
          color="accent"
          class="font-weight-bold btn-font"
          @click="addDoc()"
        >
          <v-icon
            size="30"
            left
          >mdi-plus-box</v-icon>
          Add New Poem
          <v-tooltip
            activator="parent"
            location="top"
          >Add Poem</v-tooltip>
        </v-btn>
        <v-btn
          size="large"
          variant="text"
          color="accent"
          class="font-weight-bold btn-font"
          @click="reSort(headings[isActive].heading)"
        >
          <v-icon
            size="30"
            left
          >mdi-sort</v-icon>
          Resort Poems
          <v-tooltip
            activator="parent"
            location="top"
          >Sort Poems</v-tooltip>
        </v-btn>
        <v-btn
          size="large"
          variant="text"
          color="accent"
          class="font-weight-bold btn-font"
          @click="selectItem()"
        >
          <v-icon
            size="30"
            left
          >mdi-search</v-icon>
          Search Poems
          <v-tooltip
            activator="parent"
            location="top"
          >Sort Poems</v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showtop">
      <v-col
        sm="4"
        md="4"
        lg="3"
        xl="2"
        v-for="head in headings"
        :key="head.id"
        class="ml-5"
      >
        <v-btn
          :class="isActive == head.sort ? 'active' : 'inActive'"
          size="x-large"
          variant="text"
          color="accent"
          class="chap-titles btn-font"
          @click="chngChap(head.heading, head.sort)"
        >
          {{ head.heading }}
          <v-tooltip
            activator="parent"
            location="top"
          >Change Chapter</v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
    <v-col
      v-if="editdoc || adddoc"
      xs12
      sm3
      md8
      class="mb-4"
    >
      <v-card class="bg-primary">
        <v-card-title v-if="editdoc">
          <h2 class="text-accent">Edit Poem</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="text-accent">Add New Poem</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="myForm"
            @submit.prevent
          >
            <v-text-field
              :rules="headingRules"
              required
              color="accent"
              class="edit-font"
              label="Topic"
              v-model.lazy="curdoc.heading"
            ></v-text-field>
            <v-text-field
              color="accent"
              class="edit-font"
              label="Title"
              type="text"
              v-model.lazy="curdoc.title"
            ></v-text-field>
            <v-text-field
              color="accent"
              class="edit-font"
              label="Sort"
              type="number"
              v-model.lazy="curdoc.sort"
            ></v-text-field>
            <ckeditor
              :editor="editor"
              v-model="curdoc.content"
              :config="editorConfig"
            ></ckeditor>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit()"
              color="black"
              class="mx-0 mt-3 mr-3 btn-font"
            >Save Poem</v-btn>
            <v-btn
              text
              @click="
                editdoc = false;
                adddoc = false;
                showtop = true;
                dispdoc = false;
                curdoc = [];
              "
              color="black"
              class="mx-0 mt-3 btn-font"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <table
      class="mytable mb-10"
      v-if="!dispdoc && !(adddoc || editdoc)"
    >
      <tbody>
        <tr>
          <th
            width="75%"
            class="title text-accent font-weight-bold"
          >
            Title
          </th>
          <th class="title text-accent font-weight-bold numbers">
            Sort
          </th>
          <th class="title text-accent font-weight-bold">Actions</th>
        </tr>

        <tr
          v-for="poem in poems"
          :key="poem.id"
        >
          <td
            v-if="poem.title"
            class="item-title item text-black pt-2 pb-2"
            @click="editTxt(poem.id)"
          >
            {{ poem.title }}
          </td>
          <td
            v-if="!poem.title"
            class="item-title item text-black pt-2 pb-2"
          >
            No Results
          </td>
          <td
            class="item-title numbers dates text-black pt-2 pb-2 "
            @click="editTxt(poem.id)"
          >
            {{ poem.sort }}
          </td>
          <td v-if="poem.title">
            <v-btn
              size="small"
              variant="text"
              color="black"
              class="btm-font"
              @click="editDoc(poem.id)"
            >
              <v-icon
                size="20"
                left
              >mdi-pencil</v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >Edit Title</v-tooltip>
            </v-btn>

            <v-btn
              size="small"
              variant="text"
              color="black btn-font"
              class="btn-fnt"
              @click="delDoc(poem.id)"
            >
              <v-icon
                size="20"
                left
              >mdi-delete</v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >Delete Title</v-tooltip>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="dispdoc"
      row
      class="bg-primary"
    >
      <v-card
        v-if="dispdoc && !editdoc"
        row
        flat
        class="bg-primary"
      >
        <v-card-text>
          <v-row class="bg=primary">
            <v-col
              class
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <h1 class="mt-2 mb-2 text-accent text-center">
                {{ curdoc.title }}
                <v-btn
                  size="small"
                  variant="text"
                  color="accent"
                  class="btn-font"
                  @click="(dispdoc = false), (showtop = true)"
                >
                  <v-icon
                    size="40"
                    left
                  >mdi-close</v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >Close</v-tooltip>
                </v-btn>
              </h1>
            </v-col>
          </v-row>
          <v-row class="bg-secondary">
            <v-col
              class="ck-content"
              sm="12"
              md="12"
              lg="10"
              xl="8"
            >
              <hr class="bg-secondary thick" />
              <div
                class="bg-secondary pl-5 pt-5 pb-5 text-black"
                v-html="curdoc.content"
              ></div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import shareData from "@/composables/share";
import store from "../store/store.js";
import axios from "axios";

const {
  scrollToTop,
  projects,
  fetchDocs,
  total,
  headings,
  poems,
  getPoems,
  getHeads,
} = shareData();

let search = ref("");
let curdoc = ref([]);
let refresh = ref(0);
let editdoc = ref(false);
let adddoc = ref(false);
let dispdoc = ref(false);
let showtop = ref(true);
let isActive = ref(0);
let formdata = ref([]);
let defurl = ref("https://angelnicoleworld.com/data.php");
var redraw = false;
const myForm = ref();
const headingRules = [
  (value) => {
    if (value) return true;
    return "Title is required.";
  },
  (value) => {
    if (headings.value.includes(value)) return true;
    return "Name must be a heading.";
  },
];
const submitForm = () => {
  myForm.value?.validate().then(({ valid: isValid }) => {
    console.log(isValid);
  });
};

onMounted(async () => {
  formdata = {
    action: "display",
    search: search.value,
  };
  await fetchDocs(formdata, redraw, defurl);

  formdata = {
    action: "dishead",
    search: "",
  };
  await getHeads(formdata, defurl);

  formdata = {
    action: "dispoem-d",
    search: headings.value[0].heading,
  };
  await getPoems(formdata, defurl);
});

const editor = ref(DocumentEditor);
const editorConfig = ref({
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: "https://dash.angelnicoleworld.com/upload.php",

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      "X-CSRF-TOKEN": "CSFR-Token",
      Authorization: "Bearer <JSON Web Token>",
    },
  },
  image: {
    resizeUnit: "px",
    // You need to configure the image toolbar, too, so it uses the new style buttons.
    toolbar: [
      "imageTextAlternative",
      "|",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
    ],

    styles: [
      "alignCenter",

      // This represents an image aligned to the left.
      "alignLeft",

      // This represents an image aligned to the right.
      "alignRight",
    ],
  },
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,

    // Let the users control the "download" attribute of each link.
    decorators: [
      {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "download",
        },
      },
    ],
  },
});

async function selectItem() {
  search.value = prompt("Please enter search");
  if (search.value) {
    formdata = {
      action: "findpoem",
      search: search.value,
    };
    await getPoems(formdata, defurl);
    isActive.value = poems.value[0].headsort;
  }
}

function reSort(heading) {
  var url = defurl.value;
  var formdata = {
    action: "resort-p",
    heading: heading,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "dispoem-d",
        search: headings.value[isActive.value].heading,
      };
      getPoems(formdata, defurl);
    })
    .catch((error) => alert(error));
}

function chngChap(chap, sort) {
  isActive.value = sort;
  search.value = chap;
  formdata = {
    action: "display",
    search: search.value,
  };
  fetchDocs(formdata, redraw, defurl);

  formdata = {
    action: "dispoem-d",
    search: chap,
  };
  getPoems(formdata, defurl);
}

function onReady(editor) {
  // Insert the toolbar before the editable area.
  editor.ui
    .getEditableElement()
    .parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
}

function submit() {
  var url = defurl.value;
  var action;
  if (editdoc.value) {
    action = "submitpoem";
  } else {
    action = "addpoem";
  }
  var formdata = {
    action: action,
    id: curdoc.value.id,
    heading: curdoc.value.heading,
    title: curdoc.value.title,
    content: curdoc.value.content,
    sort: curdoc.value.sort,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      formdata = {
        action: "display",
        search: "",
      };
      fetchDocs(formdata, redraw, defurl);
      formdata = {
        action: "dispoem-d",
        search: headings.value[isActive.value].heading,
      };
      getPoems(formdata, defurl);
    })
    .catch((error) => alert(error));
  editdoc.value = false;
  adddoc.value = false;
  curdoc.value = [];
  projects.value = [];
  dispdoc.value = false;
  showtop.value = true;
}

function editDoc(id) {
  var url = defurl.value;
  var formdata = {
    action: "editpoems",
    id: id,
  };
  axios
    .post(url, formdata, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then((res) => {
      const data = res.data;
      curdoc.value = data[0];
      editdoc.value = true;
      showtop.value = false;
      dispdoc.value = false;
    })
    .catch((error) => alert(error));
}

function editTxt(id) {
  for (var i = 0; i < poems.value.length; i++) {
    if (poems.value[i].id === id) {
      curdoc.value.title = poems.value[i].title;
      curdoc.value.content = poems.value[i].content;
      if (curdoc.value.content) {
        dispdoc.value = true;
        showtop.value = false;
      }
    }
  }
  // fetchDocs(formdata, redraw, "displayj");
  scrollToTop();
}

function addDoc() {
  adddoc.value = true;
  showtop.value = false;
  dispdoc.value = false;
  curdoc.value = [];
  curdoc.value.heading = headings.value[isActive.value].heading;
}

function delDoc(id) {
  if (confirm("Do you really want to delete?")) {
    var url = defurl.value;
    var formdatad = {
      action: "delpoem",
      id: id,
    };
    axios
      .post(url, formdatad, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then(() => {
        formdata = {
          action: "dispoem-d",
          search: headings.value[isActive.value].heading,
        };
        getPoems(formdata, defurl);
      })
      .catch((error) => alert(error));
  }
}
</script>

<style scoped>
.active {
  font-weight: bold;
}
.inActive {
  font-weight: normal;
}
.topic-title input {
  color: red !important;
}
.done {
  color: #689f38;
}
.ratings:hover {
  color: red;
  background-color: white;
  border: solid;
  border-color: black;
}
.poem:hover {
  background-color: orange;
  white-space: normal;
  width: auto;
}
.edit-font {
  font-size: 1.3rem;
  line-height: 1;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  width: auto;
  border: 1px solid #ddd;
  overflow: auto;
}

th,
td {
  text-align: left;
  overflow: auto;
  cursor: pointer;
  font-family: "Montserrat", sans-serif !important;
}

th {
  cursor: pointer;
}

th.title:hover {
  color: orange !important;
}

th .searchbar {
  cursor: pointer;
}

td:hover {
  white-space: normal;
  width: auto;
}

tr:hover td,
.searchbar:hover {
  background-color: orange;
}

.playit:hover {
  background-color: orange;
}

.tablediv {
  overflow: auto;
}

tr:nth-child(even) {
  background-color: #f1f8e9;
}
</style>
