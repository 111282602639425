import router from "@/router/index.js";
import axios from "axios";
import { ref } from "vue";

export default function shareData() {

    let projects = ref([]);
    let poems = ref([]);
    let headings = ref([]);
    let nores = ref("");
    let total = ref(0);
    let valueCount = ref(0);

    function forceRef() {
        router.go(0);
    }

    async function fetchDocs(form, redraw, url) {
        url = url.value;
        await axios
            .post(url, form, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((res) => {
                const data = res.data;
                projects.value = data;
                nores.value = projects.value[0];
                if (redraw) {
                    redraw = false;
                    fetchDocs(form, redraw);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getPoems(form, url) {
        url = url.value;
        await axios
            .post(url, form, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((res) => {
                const data = res.data;
                poems.value = data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getHeads(form, url) {
        url = url.value;
        await axios
            .post(url, form, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then((res) => {
                const data = res.data;
                headings.value = data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function sendOff(where) {
        router.push(where);
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }


    return { sendOff, scrollToTop, fetchDocs, projects, poems, nores, headings, total, valueCount, forceRef, getPoems, getHeads };
}
