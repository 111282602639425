<template>
  <nav>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="bg-primary pt-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-5"
        >
          <v-avatar
            size="220"
            class="rounded-pill"
            image="https://angelnicoleworld.com/images/sunflower.jpg"
          >
          </v-avatar>
        </v-col>
      </v-row>
      <div id="nav">
        <ul>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-format-title </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/"
            >
              Headings</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-information </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/editabout"
            >
              Edit About</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-file-edit </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/editbook"
            >
              Edit Book</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-book-open </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/poems"
            >
              Edit Poems</router-link>
          </li>
          <li class="ml-5">
            <v-icon size="x-large"> mdi-plus-box-multiple </v-icon>
            <router-link
              class="accent--text text-h5"
              to="/photos"
            >
              Edit Photos</router-link>
          </li>
        </ul>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      text
      class="bg-secondary"
      app
    >
      <v-icon
        size="50"
        color="black"
        class="ml-5"
        @click="drawer = !drawer"
      >mdi-menu</v-icon>
      <v-toolbar-title class="mt-3 mb-3 text-accent text-subtitle-2 text-xl-h4 text text-lg-h5 text-md-h6 text-sm-subtitle-1 bg-secondary">
        <span class="text-accent font-weight-bold banner"> L.O.V.E. </span>
        <span class="banner"> (Living Openly, Valuing Existence) </span>
      </v-toolbar-title>
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
    };
  },
};
</script>

<style scoped>
.banner {
  font-family: "Montserrat", sans-serif !important;
}
ul {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 16px;
  list-style-type: none;
}

li {
  margin-bottom: 10px;
}

a {
  color: #263238 !important;
}

#nav a.router-link-exact-active {
  color: #b71c1c !important;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: #e53935 !important;
  font-family: "Montserrat", sans-serif !important;
}

.home {
  cursor: pointer;
}
</style>
