// store.js
import { reactive } from 'vue'

export const store = reactive({
  orders_search: "",
  orders_fld: "",
  comp_search: "",
  comp_fld: "",
  compo_search_pro: "",
  compo_search: "",
  compo_fld: "",
  new_search: "",
  new_fld: ""
})

export default () => ({
  store
})