<template>
  <v-app>
    <navBar />
    <v-main class="bg-primary">
      <router-view :key="$route.path"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import navBar from "@/components/navBar.vue";
export default {
  name: "App",
  components: { navBar },
};
</script>

<style>
.btn-font {
  font-family: "Montserrat", sans-serif !important;
}
.v-dialog__content {
  align-items: flex-start !important;
  justify-content: flex-right !important;
}
.topic-title input {
  color: black !important;
}
.ck-content p,
.ck-content li {
  color: #202020;
  font-size: 1.5rem;
  letter-spacing: 1px;
  line-height: 130%;
}
html {
  width: 100%;
}

body {
  width: 100%;
}

.tablediv {
  overflow: auto;
}

tr:nth-child(even) {
  background-color: #cfd8dc;
}
.numbers {
  text-align: right;
}
h1,
h2,
h3,
h4,
h5,
h6 .title {
  letter-spacing: 1px;
  line-height: 160%;
  font-family: "Montserrat", sans-serif !important;
}
p,
li,
.edit-font {
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 150%;
  font-family: "Esteban", serif !important;
}

.v-text-field input {
  font-size: 1.4em !important;
}

li {
  margin-bottom: 10px;
}

a {
  color: blue !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  width: 70%;
  border: 1px solid #ddd;
}

th,
td {
  padding: 5px 10px 5px 10px;
  text-align: left;
  cursor: pointer;
}

th {
  cursor: pointer;
  text-align: left;
}

th.title:hover {
  color: orange !important;
}

th,
.searchbar {
  cursor: pointer;
}

td:hover {
  white-space: normal;
  width: auto;
}

tr:hover td,
.searchbar:hover {
  background-color: orange;
}

.item-title,
.title {
  font-size: 1.4rem !important;
  line-height: 1.5;
  letter-spacing: 1px;
  line-height: 28px;
  font-weight: 500;
  color: #330000;
}

.v-date-picker-table {
  height: 100% !important;
  max-height: 300px !important;
}

.ck-editor__editable {
  min-height: 500px;
}

.project.not-started {
  border-left: 6px solid #f9a825;
}
.project.on-track {
  border-left: 6px solid #2e7d32;
}
.project.behind {
  border-left: 6px solid #b71c1c;
}
.project.complete {
  border-left: 6px solid #0d47a1;
}
.v-chip.not-started {
  background: #f9a825 !important;
}
.v-chip.on-track {
  background: #2e7d32 !important;
}
.v-chip.behind {
  background: #b71c1c !important;
}
.v-chip.complete {
  background: #0d47a1 !important;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.custom-block-indent-a {
  margin-left: 10%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}
.side-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.passform {
  border-style: groove;
  border: 2px solid red;
  height: 40px;
  cursor: pointer;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  img,
  figure {
    max-width: 250px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  img,
  figure {
    max-width: 350px !important;
  }
}
</style>
